import React, { useEffect, useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import { ColorSchemeSelector } from '../utils/global'
import { TeamMember } from '../types/types'
import TeamModal from './TeamModal'
import TeamTile from './TeamTile'

interface TeamListingProps {
  data: {
    darkMode: boolean
    alternateBackgroundColor: boolean
    team: {
      id: string
      slug: string
      teamName: string
    }
    teamMembers?: TeamMember[]
  }
  customClass?: string
}

const TeamListing = ({
  data: { darkMode, alternateBackgroundColor, team, teamMembers },
  customClass,
}: TeamListingProps): JSX.Element => {
  darkMode ? (alternateBackgroundColor = false) : null

  const { allDatoCmsTeamMember } = useStaticQuery(graphql`
    query {
      allDatoCmsTeamMember(sort: { fields: sortingValue }) {
        nodes {
          id
          firstName
          lastName
          sortingValue
          jobTitle
          linkedinUrl
          description
          team {
            teamName
            slug
          }
          tileImage {
            fluid(maxWidth: 1200) {
              sizes
              src
              srcSet
              aspectRatio
            }
          }
          bioImage {
            fluid(maxWidth: 3840) {
              sizes
              src
              srcSet
              aspectRatio
            }
          }
        }
      }
    }
  `)

  const [members, setMembers] = useState<TeamMember[]>([])
  const [activeMember, setActiveMember] = useState<number>(0)
  const [modalOpen, setModalOpen] = useState(false)

  const handleOnClick = (index) => {
    setActiveMember(index)
    setModalOpen(!modalOpen)
  }

  useEffect(() => {
    setMembers(
      teamMembers ? teamMembers : allDatoCmsTeamMember.nodes.filter((member) => member.team?.slug === team.slug)
    )
  }, [teamMembers])

  return (
    <section className={`${customClass} ${ColorSchemeSelector('team-listing', alternateBackgroundColor, darkMode)}`}>
      <TeamModal
        isOpen={modalOpen}
        closeModal={() => setModalOpen(false)}
        activeMember={activeMember}
        members={members}
      />
      <div className="team-listing__inner container">
        {/* <h3 className="team-listing__team-name">{team.teamName}</h3> */}
        {members.length > 0 &&
          members.map((member, index) => {
            return (
              <TeamTile
                key={member.id}
                onClick={() => handleOnClick(index)}
                tileImage={member.tileImage}
                firstName={member.firstName}
                jobTitle={member.jobTitle}
                lastName={member.lastName}
                id={member.id}
              />
            )
          })}
      </div>
    </section>
  )
}

export default TeamListing
