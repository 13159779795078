import { ColorSchemeSelector, HeadingTag, plyrOptions } from '../utils/global'
import Img, { FluidObject } from 'gatsby-image'
import React, { useEffect } from 'react'

import Quotation from './atoms/Quotation'

interface VideoBlockProps {
  data: {
    heading: string
    body: string
    alternateBackgroundColor: boolean
    darkMode: boolean
    reversed: boolean
    mediaUrl: string
    media: {
      isImage: boolean
      url: string
      fluid: FluidObject | FluidObject[]
    }
  }
}

const VideoBlock = ({ data }: VideoBlockProps): JSX.Element => {
  const { heading, body, darkMode, reversed, mediaUrl, media } = data
  let { alternateBackgroundColor } = data
  darkMode ? (alternateBackgroundColor = false) : null

  useEffect(() => {
    if (typeof window !== 'undefined' && typeof document !== 'undefined') {
      // eslint-disable-next-line
      const Plyr = require('plyr')
      Array.from(document.querySelectorAll('#player-controls')).map((p) => new Plyr(p, plyrOptions))
    }
  }, [media])

  return (
    <section
      data-anim-parent
      className={`${ColorSchemeSelector('video-block', alternateBackgroundColor, darkMode)}${
        reversed ? ' video-block--reversed' : ''
      }${heading ? ' video-block--text' : ''}`}
    >
      <div className="video-block__inner container">
        <div className="video-block__right-panel">
          {mediaUrl ? (
            <div className="video-block__video-container">
              <div className="plyr__video-embed" id="player-controls">
                <iframe src={`${mediaUrl}`} allowFullScreen allow="autoplay"></iframe>
              </div>
            </div>
          ) : media ? (
            media.isImage ? (
              <div className="video-block__image">
                <Img fluid={media.fluid} />
              </div>
            ) : (
              <div className="video-block__video-container">
                <iframe src={media?.url} allow="autoplay, fullscreen"></iframe>
              </div>
            )
          ) : (
            <div className="video-block__placeholder" />
          )}
        </div>
        <div className="video-block__left-panel">
          <div className="video-block__text-wrapper">
            <Quotation className="video-block__quote" />
            {heading && HeadingTag(3, 'video-block__heading', heading)}
            <div className="video-block__subtitle-line-wrapper">
              <div data-anim data-anim-grow className="feature-line"></div>
            </div>
            {body && (
              <div className="p video-block__body" dangerouslySetInnerHTML={{ __html: `<div> ${body} </div>` }} />
            )}
          </div>
        </div>
      </div>
    </section>
  )
}

export default VideoBlock
