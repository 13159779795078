import * as React from 'react'

import { ColorSchemeSelector } from '../utils/global'
import Img from 'gatsby-image'
import { Quote } from '../types/types'
import QuoteMarks from '../images/quote-marks.svg'
import SlideButton from './atoms/SlideButton'

interface QuoteBlockType {
  data: Quote
}

const DatoCmsQuote = ({ data }: QuoteBlockType): JSX.Element => {
  const { body, teamMember, darkMode } = data
  let { alternateBackgroundColor } = data
  darkMode === true ? (alternateBackgroundColor = false) : (alternateBackgroundColor = alternateBackgroundColor)

  return (
    <section data-anim-parent className={`${ColorSchemeSelector('quote', alternateBackgroundColor, darkMode)}`}>
      <div className="quote__inner container">
        {teamMember.tileImage && (
          <div className="quote__image">
            <div data-anim data-anim-translate-up data-anim-fade-in className="quote__image--wrapper">
              <Img fluid={teamMember.tileImage.fluid} />
            </div>
            <QuoteMarks data-anim data-anim-fade-in className="quote__image--decal" />
          </div>
        )}
        <div className="quote__text-wrapper">
          {body && (
            <div
              data-anim
              data-anim-fade-in
              className="quote__body body-large"
              dangerouslySetInnerHTML={{ __html: `<div> ${body} </div>` }}
            />
          )}
          {teamMember && (
            <div className="quote__team-member">
              <SlideButton text={`${teamMember.firstName} ${teamMember.lastName}`} destination="/our-people" />
              <p className="caption quote__team-member--position">{teamMember.jobTitle}</p>
            </div>
          )}
        </div>
      </div>
    </section>
  )
}

export default DatoCmsQuote
