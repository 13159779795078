import React, { useRef } from 'react'

import Arrow from './atoms/Arrow'
import { HeadingTag } from '../utils/global'
import { HeroBanner } from '../types/types'
import Img from 'gatsby-image'

interface HeroBannerBlockType {
  data: HeroBanner
}

const DatoCmsHeroBanner = ({ data }: HeroBannerBlockType): JSX.Element => {
  const { body, title, fullHeight, background, mobileBackground, backgroundOverlay, reverseTextColor } = data
  const heroRef = useRef<null | HTMLDivElement>(null)

  const scrollTo = () => {
    heroRef.current?.nextElementSibling?.scrollIntoView({ behavior: 'smooth' })
  }

  return (
    <section
      ref={heroRef}
      className={`hero-banner${reverseTextColor ? ' reverse-color' : ''}${
        fullHeight ? ' hero-banner__full-height' : ''
      }`}
    >
      <div className="hero-banner__background">
        {backgroundOverlay && <div className="hero-banner__bg-overlay"></div>}
        {mobileBackground ? (
          mobileBackground?.isImage ? (
            <Img className="hero-banner__background--img mobile-only" fluid={mobileBackground.fluid} />
          ) : (
            <div className="video-container mobile-only">
              <video className="video-container__video" playsInline autoPlay muted loop>
                <source src={mobileBackground?.url} type="video/mp4" />
              </video>
            </div>
          )
        ) : null}
        {background?.isImage ? (
          <Img
            className={`hero-banner__background--img${mobileBackground ? ' desktop-only' : ''}`}
            fluid={background.fluid}
            loading={'lazy'}
            fadeIn={true}
          />
        ) : (
          <div className={`video-container${mobileBackground ? ' desktop-only' : ''}`}>
            <video className="video-container__video" playsInline autoPlay muted loop>
              <source src={background?.url} type="video/mp4" />
            </video>
          </div>
        )}
      </div>
      <div className="hero-banner__inner container">
        {title && HeadingTag(1, 'hero-banner__title', title)}
        {body && (
          <div className="hero-banner__body body-large" dangerouslySetInnerHTML={{ __html: `<div> ${body} </div>` }} />
        )}
        <div onClick={scrollTo} className="hero-banner__arrow-wrapper">
          <Arrow className="hero-banner__arrow" />
        </div>
      </div>
    </section>
  )
}

export default DatoCmsHeroBanner
