import * as React from 'react'

import { ColorSchemeSelector, HeadingTag } from '../utils/global'
import SideArrow from './atoms/SideArrow'
import SubtitleTag from './atoms/SubtitleTag'

interface IconBlock {
  id: string
  caption: string
  title: string
  icon: {
    url: string
  }
  linkToUrl: string
  linkToMedia: {
    url: string
  }
  openNewTab: boolean
  buttonText: string
  leftAlign: boolean
  __typename: string
}

interface DataType {
  subtitle: string
  heading: string
  description: string
  centerAlign: boolean
  iconBlocks: IconBlock[]
  darkMode: boolean
  alternateBackgroundColor: boolean
}

interface MultiColIconType {
  data: DataType
}

const IconBlockInner = ({ icon }) => (
  <>
    {icon.icon && <img className={`multi-col-icon__icon--image`} src={icon.icon?.url} />}
    {icon.title && (
      <h3 className="multi-col-icon__icon--title" dangerouslySetInnerHTML={{ __html: `<div> ${icon.title} </div>` }} />
    )}
    {icon.caption && (
      <div
        className="p multi-col-icon__icon--caption"
        dangerouslySetInnerHTML={{ __html: `<div> ${icon.caption} </div>` }}
      />
    )}
  </>
)

const MultiColIcon = ({ data }: MultiColIconType): JSX.Element => {
  const { subtitle, heading, description, centerAlign, darkMode, iconBlocks } = data
  let { alternateBackgroundColor } = data
  darkMode ? (alternateBackgroundColor = false) : null

  const center = centerAlign ? ' center-align' : ''

  return (
    <section
      data-anim-parent
      className={`${ColorSchemeSelector('multi-col-icon', alternateBackgroundColor, darkMode)} multi-col-icon--${
        iconBlocks.length
      }`}
    >
      <div className="multi-col-icon__inner container">
        {subtitle && <SubtitleTag subtitle={subtitle} className={`multi-col-icon__subtitle${center}`} />}
        {heading && (
          <>
            {HeadingTag(3, `multi-col-icon__heading${center}`, heading)}
            <div data-anim data-anim-grow className={`feature-line full-screen${center}`}></div>
          </>
        )}
        {description && (
          <div
            className={`p multi-col-icon__description${center}`}
            dangerouslySetInnerHTML={{ __html: `<div> ${description} </div>` }}
          />
        )}
        {iconBlocks.map((icon, index) => {
          const iconLink = icon.linkToMedia?.url ? icon.linkToMedia?.url : icon.linkToUrl ? icon.linkToUrl : null
          return iconLink ? (
            <a
              className={`multi-col-icon__icon icon-${index + 1} ${icon.leftAlign ? 'icon--left-align' : ''}`}
              key={`${index}--${icon.id}`}
              href={iconLink}
              target={icon.openNewTab ? '__blank' : '_self'}
            >
              <IconBlockInner icon={icon} />
              {icon.buttonText && (
                <div className="multi-col-icon__icon--button">
                  <p className="multi-col-icon__icon--button-text">{icon.buttonText}</p>
                  <SideArrow iconClass="multi-col-icon__icon--button-icon" />
                </div>
              )}
            </a>
          ) : (
            <div
              className={`multi-col-icon__icon icon-${index + 1} ${icon.leftAlign ? 'icon--left-align' : ''}`}
              key={`${index}--${icon.id}`}
            >
              <IconBlockInner icon={icon} />
            </div>
          )
        })}
      </div>
    </section>
  )
}

export default MultiColIcon
