import { ColorSchemeSelector, HeadingTag } from '../utils/global'

import React from 'react'
import SubtitleTag from './atoms/SubtitleTag'

interface TwoColTextData {
  columnOne: string
  columnTwo: string
  heading: string
  subtitle: string
  darkMode: boolean
  largeHeading: boolean
  reversed: boolean
  alternateBackgroundColor: boolean
  addExtraPadding: boolean
}

interface TwoColTextType {
  data: TwoColTextData
}

const TwoColText = ({ data }: TwoColTextType): JSX.Element => {
  const { columnOne, columnTwo, heading, subtitle, darkMode, largeHeading, reversed, addExtraPadding } = data
  let { alternateBackgroundColor } = data
  darkMode ? (alternateBackgroundColor = false) : null

  return (
    <section
      data-anim-parent
      className={`${ColorSchemeSelector('aligned-text aligned-text__two-col', alternateBackgroundColor, darkMode)}${
        reversed ? ' aligned-text--reversed' : ''
      }${addExtraPadding ? ' extra-padding' : ''}`}
    >
      <div className="aligned-text__inner container">
        {subtitle ? (
          <>
            <SubtitleTag subtitle={subtitle} className="aligned-text__subtitle-wrapper" />
            <div className="aligned-text__subtitle-line-wrapper">
              <div data-anim data-anim-grow className="feature-line"></div>
            </div>
          </>
        ) : (
          <div className="aligned-text__subtitle-line-wrapper aligned-text__no-subtitle">
            <div data-anim data-anim-grow className="feature-line"></div>
          </div>
        )}
        {heading && HeadingTag(largeHeading ? 2 : 3, 'aligned-text__header', heading)}
        {columnOne && (
          <div
            className="p aligned-text__column-one"
            dangerouslySetInnerHTML={{ __html: `<div> ${columnOne} </div>` }}
          />
        )}
        {columnTwo && (
          <div
            className="p aligned-text__column-two"
            dangerouslySetInnerHTML={{ __html: `<div> ${columnTwo} </div>` }}
          />
        )}
      </div>
    </section>
  )
}

export default TwoColText
