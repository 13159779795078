import React from 'react'
import { ColorSchemeSelector } from '../utils/global'
import ButtonStandard from './atoms/ButtonStandard'

interface CTABlockData {
  buttonDest: string
  buttonText: string
  isExternalLink: boolean
  darkMode: boolean
  alternateBackgroundColor: boolean
  alignCenter: boolean
}

interface CTABlockType {
  data: CTABlockData
}

const DatoCmsCtaBlock = ({ data }: CTABlockType): JSX.Element => {
  const { darkMode, buttonText, buttonDest, alignCenter, isExternalLink } = data
  let { alternateBackgroundColor } = data
  darkMode ? (alternateBackgroundColor = false) : null

  return (
    <section
      data-anim-parent
      className={`${ColorSchemeSelector('cta-block', alternateBackgroundColor, darkMode)}${
        alignCenter ? ' cta-block--center' : ''
      }`}
    >
      <div className="cta-block__inner container">
        {buttonText && <ButtonStandard href={buttonDest} text={buttonText} isExternalLink={isExternalLink} />}
      </div>
    </section>
  )
}

export default DatoCmsCtaBlock
