import { ScrollScene } from 'scrollscene'

const anim = (dataAttr: string, className: string): void => {
  const allElements = Array.from(document.querySelectorAll(dataAttr))
  allElements.forEach((element) => {
    if (element.closest('[data-anim-parent]')) {
      new ScrollScene({
        triggerElement: element.closest('[data-anim-parent]'),
        triggerHook: 0.6,
        toggle: {
          element: element,
          className: className,
        },
      })
    }
  })
}

const init = (): void => {
  anim('[data-anim-translate-up]', 'translate-up')
  anim('[data-anim-fade-in]', 'fade-in')
  anim('[data-anim-grow]', 'grow')
}

export default { init }
