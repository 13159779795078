import ExternalLinkIcon from '../components/atoms/InsightsExternalLinkIcon'
import { Image } from '../types/types'
import Img from 'gatsby-image'
import React from 'react'
import SideArrow from './atoms/SideArrow'
import moment from 'moment'
import { fetchSVG } from '../utils/global'

interface ArticleTileProps {
  item: {
    title: string
    author: string
    slug: string
    date: string
    articleType: {
      id: string
      bannerImage: Image
      tileImage: Image
      __typename: string
    }[]
    category: {
      category: string
      categoryIcon: {
        url: string
      }
    }
    meta: {
      createdAt: string
    }
  }
}

const ArticleTile = ({ item }: ArticleTileProps): JSX.Element => {
  const itemType = item.articleType[0]
  const fluidImage = itemType.tileImage?.fluid ? itemType.tileImage.fluid : itemType.bannerImage?.fluid

  const handleClassName = (itemType) => {
    switch (itemType) {
      case 'DatoCmsArticleBlog':
        return ' article-tile__blog'
        break
      case 'DatoCmsArticleExternalLink':
        return ' article-tile__link'
        break
      case 'DatoCmsArticlePodcast':
        return ' article-tile__podcast'
        break
      case 'DatoCmsArticleVideo':
        return ' article-tile__video'
        break
      default:
        return ''
    }
  }

  const handleIcon = (category, type) => {
    const SVGIcon = fetchSVG(category.categoryIcon.url)

    if (type.__typename === 'DatoCmsArticleExternalLink' && !type.haveDetailedPage) {
      return <ExternalLinkIcon title={'External Link'} />
    } else {
      return <div dangerouslySetInnerHTML={{ __html: SVGIcon }} />
    }
  }

  return (
    <div className={`article-tile${handleClassName(itemType.__typename)}`}>
      {itemType.tileImage || itemType.bannerImage || itemType.__typename === 'DatoCmsArticlePodcast' ? (
        <div className="article-tile__image">
          <Img fluid={fluidImage} />
        </div>
      ) : null}
      <div className="article-tile__body">
        <div className="article-tile__top-panel">
          <p className="article-tile__date">{moment(item.date).format('Do, MMM YYYY')}</p>
          <div className="article-tile__icon">{handleIcon(item.category, itemType)}</div>
        </div>
        <h3 className="article-tile__title">{item.title}</h3>
        <div className="article-tile__bottom-panel">
          <SideArrow iconClass="article-tile__arrow" />
          <p className="article-tile__category">{item.category.category}</p>
        </div>
      </div>
    </div>
  )
}

export default ArticleTile
