import { Image } from '../types/types'
import Img from 'gatsby-image'
import React from 'react'

interface TeamTileProps {
  index?: number
  id: string
  firstName: string
  lastName: string
  jobTitle: string
  tileImage: Image
  onClick: () => void
}

const TeamTile = ({ firstName, lastName, jobTitle, tileImage, id, onClick }: TeamTileProps): JSX.Element => {
  return (
    <div onClick={onClick} className="team-tile" key={id}>
      {tileImage?.fluid ? (
        <Img className="team-tile__photo" fluid={tileImage.fluid} />
      ) : (
        <div className="team-tile__no-photo-wrapper">
          <div className="team-tile__no-photo"></div>
        </div>
      )}
      <h3 className="team-tile__name">
        {firstName} {lastName}
      </h3>
      <p className="team-tile__job-title">{jobTitle}</p>
      <div className="right-arrow">
        <div className="right-arrow__line"></div>
      </div>
    </div>
  )
}

export default TeamTile
