import { ColorSchemeSelector, HeadingTag } from '../utils/global'

import React from 'react'
import SubtitleTag from './atoms/SubtitleTag'

interface CenteredTextData {
  body: string
  heading: string
  subtitle: string
  darkMode: boolean
  largeHeading: boolean
  alternateBackgroundColor: boolean
  addExtraPadding: boolean
}

interface CenteredTextType {
  data: CenteredTextData
}

const DatoCmsCenteredText = ({ data }: CenteredTextType): JSX.Element => {
  const { body, heading, subtitle, darkMode, largeHeading, addExtraPadding } = data
  let { alternateBackgroundColor } = data
  darkMode ? (alternateBackgroundColor = false) : null

  return (
    <section
      data-anim-parent
      className={`${ColorSchemeSelector('centered-text', alternateBackgroundColor, darkMode)}${
        addExtraPadding ? ' extra-padding' : ''
      }`}
    >
      <div className="centered-text__inner container">
        {subtitle && <SubtitleTag subtitle={subtitle} className="centered-text__subtitle-wrapper" />}
        {heading && HeadingTag(largeHeading ? 2 : 3, 'centered-text__header', heading)}
        {body && <div className="p centered-text__body" dangerouslySetInnerHTML={{ __html: `<div> ${body} </div>` }} />}
      </div>
    </section>
  )
}

export default DatoCmsCenteredText
