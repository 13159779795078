import React from 'react'

const DropDownArrow = (): JSX.Element => {
  return (
    <svg width="29" height="30" viewBox="0 0 29 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.7422 27.0522L12.7422 1.28574e-07L15.6836 0L15.6836 27.0522L12.7422 27.0522Z"
        fill="#333347"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 17.3658L1.95418 15.1673L14.2135 26.0645L26.4729 15.1673L28.4271 17.3658L14.2135 30L0 17.3658Z"
        fill="#333347"
      />
    </svg>
  )
}

export default DropDownArrow
