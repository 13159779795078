import React, { useEffect, useState, FC } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { ColorSchemeSelector, checkIfOdd } from '../utils/global'

import TeamListing from './TeamListing'

interface TeamWithSortProps {
  data: {
    darkMode: boolean
    alternateBackgroundColor: boolean
    showSort?: boolean
  }
}

const TeamWithSort: FC<TeamWithSortProps> = ({ data: { darkMode, alternateBackgroundColor, showSort = false } }) => {
  darkMode ? (alternateBackgroundColor = false) : null

  const { allDatoCmsTeamMember } = useStaticQuery(graphql`
    query {
      allDatoCmsTeamMember(sort: { fields: sortingValue }) {
        nodes {
          id
          firstName
          lastName
          jobTitle
          linkedinUrl
          description
          sortingValue
          team {
            teamName
            slug
          }
          tileImage {
            fluid(maxWidth: 1200) {
              sizes
              src
              srcSet
              aspectRatio
            }
          }
          bioImage {
            fluid(maxWidth: 3840) {
              sizes
              src
              srcSet
              aspectRatio
            }
          }
        }
      }
    }
  `)

  const [teams, setTeams] = useState({})
  const [activeSortVal, setActiveSort] = useState<'team' | 'abc'>('abc')
  const [currentScroll, setScroll] = useState<number>(0)
  const [animPlay, setAnim] = useState<boolean>(false)

  useEffect(() => {
    const teams = allDatoCmsTeamMember.nodes.reduce((acc, elem) => {
      if (elem.team?.teamName) {
        acc[elem.team?.teamName] = allDatoCmsTeamMember.nodes.filter(
          (item) => item.team?.teamName == elem.team.teamName
        )
      }
      return acc
    }, {})
    teams['all'] = allDatoCmsTeamMember.nodes
    setTeams(teams)
  }, [])

  const changeSort = async (event, val) => {
    event.preventDefault()
    setScroll(window.pageYOffset)
    setActiveSort(val)
    setAnim(true)
    setTimeout(() => {
      setAnim(false)
    }, 200)
  }

  useEffect(() => {
    requestAnimationFrame(() => {
      window.scrollTo(0, currentScroll)
    })
  }, [activeSortVal])

  return (
    <>
      <section className={ColorSchemeSelector('team-sort', alternateBackgroundColor, darkMode)}>
        {showSort && (
          <div className="team-sort__inner container">
            <div className="team-sort__filters">
              <button
                className="team-sort__button"
                onClick={(e) => changeSort(e, 'abc')}
                disabled={activeSortVal === 'abc'}
              >
                abc
              </button>
              <button
                className="team-sort__button"
                onClick={(e) => changeSort(e, 'team')}
                disabled={activeSortVal === 'team'}
              >
                team
              </button>
            </div>
          </div>
        )}
        {activeSortVal === 'abc' ? (
          <TeamListing
            key="team-sort-item--all"
            customClass={animPlay ? 'fade-in' : ''}
            data={{
              darkMode: false,
              alternateBackgroundColor: false,
              team: { id: '', slug: 'All', teamName: 'All' },
              teamMembers: teams['all'],
            }}
          />
        ) : (
          Object.keys(teams)
            .filter((teamKey) => teamKey !== 'all')
            .map((teamItem, index) => {
              return (
                <TeamListing
                  key={`team-sort-item--${index}`}
                  customClass={animPlay ? 'fade-in' : ''}
                  data={{
                    darkMode: false,
                    alternateBackgroundColor: checkIfOdd(index),
                    team: { id: '', slug: teamItem, teamName: teamItem },
                    teamMembers: teams[teamItem],
                  }}
                />
              )
            })
        )}
      </section>
    </>
  )
}

export default TeamWithSort
