import React, { useEffect, useState } from 'react'
import SideArrow from './atoms/SideArrow'
import jobsDataJSON from '../data/jobs.json'

interface JobListing {
  noResultsMessage: string
}

interface JobListingType {
  data: JobListing
}

interface Job {
  title: string
  location: string
  url: string
}

const DatoCmsJobListing = ({ data }: JobListingType): JSX.Element => {
  const { noResultsMessage } = data
  const [jobsData, setJobsData] = useState<Job[]>([])
  const [error, setError] = useState(false)

  const fetchData = async () => {
    const results = await fetch('/.netlify/functions/fetch-jobs')
    const data = await results.json()
    if (results.status === 500 || data.length <= 0) {
      setError(true)
    }

    setJobsData(data)
  }

  useEffect(() => {
    if (jobsDataJSON.length <= 0) {
      fetchData()
    } else {
      setJobsData(jobsDataJSON)
    }
  }, [])

  const renderListings = () => {
    return jobsData.length > 0 ? (
      jobsData.map((job: Job, index) => (
        <li key={`job--${index}`} className="job-listing__job">
          <a
            className="job-listing__job--link"
            href={job.url}
            target="__blank"
            aria-label={`LinkedIn Job Ad: ${job.title}`}
          >
            <h3 className="job-listing__job--title">
              {job.title} <SideArrow iconClass="job-listing__job--arrow" />
            </h3>
            <p className="body-large job-listing__job--location">{job.location}</p>
          </a>
        </li>
      ))
    ) : (
      <li className="h3">{noResultsMessage}</li>
    )
  }

  return (
    <section data-anim-parent className="job-listing light">
      {!error ? (
        jobsData.length > 0 ? (
          <ul className="job-listing__list container">{renderListings()}</ul>
        ) : (
          <div className="container">
            <div className="loader"></div>
          </div>
        )
      ) : (
        <div className="container">
          <div className="h3">{noResultsMessage}</div>
        </div>
      )}
    </section>
  )
}

export default DatoCmsJobListing
