import 'swiper/scss'
import 'swiper/scss/navigation'

import { ButtonsType, TeamMember } from '../types/types'
import React, { useEffect, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Navigation } from 'swiper'
import { graphql, useStaticQuery } from 'gatsby'

import ButtonStandard from './atoms/ButtonStandard'
import { ColorSchemeSelector } from '../utils/global'
import TeamModal from './TeamModal'
import TeamTile from './TeamTile'

SwiperCore.use([Navigation])

interface TeamCarouselProps {
  data: {
    alternateBackgroundColor: boolean
    darkMode: boolean
    members: TeamMember[]
    button: ButtonsType
    randomise: boolean
  }
}

const TeamCarousel = ({
  data: { alternateBackgroundColor, darkMode, randomise, members, button },
}: TeamCarouselProps): JSX.Element => {
  darkMode ? (alternateBackgroundColor = false) : null

  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [activeMember, setActiveMember] = useState<number>(0)

  const handleOnClick = (index) => {
    setActiveMember(index)
    setModalOpen(!modalOpen)
  }

  const { allDatoCmsTeamMember } = useStaticQuery(graphql`
    {
      allDatoCmsTeamMember {
        nodes {
          id
          firstName
          lastName
          jobTitle
          linkedinUrl
          description
          team {
            teamName
          }
          tileImage {
            fluid(maxWidth: 1200, forceBlurhash: true) {
              sizes
              src
              srcSet
              aspectRatio
            }
          }
          bioImage {
            fluid(maxWidth: 3840, forceBlurhash: true) {
              sizes
              src
              srcSet
              aspectRatio
            }
          }
        }
      }
    }
  `)

  const [randomMembers] = useState(allDatoCmsTeamMember.nodes.sort(() => Math.random() - Math.random()).slice(0, 5))
  const [modalMembers, setModalMembers] = useState(members)
  const [renderReset, setRenderReset] = useState<boolean>(false)

  useEffect(() => {
    if (randomise) {
      setModalMembers(randomMembers)
    }
  }, [randomMembers])

  useEffect(() => {
    setTimeout(() => {
      setRenderReset(true)
    }, 1000)
  }, [])

  const swiperContent = modalMembers.map((member, index) => {
    return (
      <SwiperSlide key={index}>
        <TeamTile
          onClick={() => handleOnClick(index)}
          tileImage={member.tileImage}
          firstName={member.firstName}
          jobTitle={member.jobTitle}
          lastName={member.lastName}
          id={member.id}
        />
      </SwiperSlide>
    )
  })

  return (
    <section className={ColorSchemeSelector('team-carousel', alternateBackgroundColor, darkMode)}>
      <TeamModal
        isOpen={modalOpen}
        closeModal={() => setModalOpen(false)}
        activeMember={activeMember}
        members={modalMembers}
      />
      <div className="team-carousel__inner container">
        {renderReset && (
          <Swiper
            spaceBetween={0}
            slidesPerView="auto"
            observer={true}
            observeParents={true}
            breakpoints={{
              768: {
                slidesPerView: 2,
              },
              992: {
                slidesPerView: 3,
              },
            }}
            navigation
          >
            {swiperContent}
          </Swiper>
        )}
        {button && (
          <ButtonStandard
            href={button.buttonUrl}
            text={button.buttonText}
            isExternalLink={button.isExternalLink}
            className="team-carousel__cta"
          />
        )}
      </div>
    </section>
  )
}

export default TeamCarousel
