import * as React from 'react'

interface SlideButtonType {
  text: string
  destination: string
  customClass?: string
}

const SlideButton = ({ text, destination, customClass }: SlideButtonType): JSX.Element => {
  return (
    <a className={`${customClass} slide-button h3`} href={destination}>
      <span className="slide-button__left"></span>
      {text}
      <span className="slide-button__right"></span>
    </a>
  )
}

export default SlideButton
