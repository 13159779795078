import React from 'react'
import Img, { FluidObject } from 'gatsby-image'
import { ColorSchemeSelector } from '../utils/global'
import SideArrow from './atoms/SideArrow'

interface PartnerBlockProps {
  data: {
    partnerName: string
    description: string
    externalUrl: string
    logo: { fluid: FluidObject }
    darkMode: boolean
    reversed: boolean
    alternateBackgroundColor: boolean
  }
}

const PartnerBlock = ({ data }: PartnerBlockProps): JSX.Element => {
  const { partnerName, description, externalUrl, logo, darkMode, reversed } = data
  let { alternateBackgroundColor } = data
  darkMode ? (alternateBackgroundColor = false) : null

  return (
    <section
      className={`${ColorSchemeSelector('partner-block', alternateBackgroundColor, darkMode)}${
        reversed ? ' partner-block--reversed' : ''
      }`}
    >
      <div className="partner-block__inner container">
        <div className="partner-block__logo">
          <Img fluid={logo?.fluid} className="partner-block__logo--wrapper" imgStyle={{ position: 'relative' }} />
        </div>
        <div className="partner-block__details">
          {partnerName && <h2 className="partner-block__partner-name">{partnerName}</h2>}
          {description && <p className="partner-block__description">{description}</p>}
          {externalUrl && (
            <div className="partner-block__link">
              <a href={externalUrl} className="body-large partner-block__cta" target="_blank" rel="noreferrer">
                <span>Find out more</span> <SideArrow iconClass="partner-block__cta--arrow" />
              </a>
            </div>
          )}
        </div>
      </div>
    </section>
  )
}

export default PartnerBlock
