import React from 'react'
import Img from 'gatsby-image'
import SlideButton from './atoms/SlideButton'
import QuoteMarks from '../images/quote-marks.svg'
import { ColorSchemeSelector } from '../utils/global'
import { TeamMember } from '../types/types'

interface TwoColQuoteProps {
  data: {
    quotes: {
      body: string
      teamMember: TeamMember
      alternateBackgroundColor: boolean
      darkMode: boolean
    }[]
  }
}

const TwoColQuote = ({ data }: TwoColQuoteProps): JSX.Element => {
  const { quotes } = data

  return (
    <section data-anim-parent className="two-col-quote">
      <div className="two-col-quote__inner container">
        {quotes &&
          quotes.map(({ body, teamMember, alternateBackgroundColor, darkMode }, i) => {
            darkMode ? alternateBackgroundColor === false : null
            return (
              <div
                className={`${ColorSchemeSelector(
                  `two-col-quote__quote quote-${i + 1}`,
                  alternateBackgroundColor,
                  darkMode
                )}`}
                key={teamMember.firstName}
              >
                {teamMember.tileImage && (
                  <div className="quote__image two-col-quote__image">
                    <div data-anim data-anim-translate-up data-anim-fade-in className="quote__image--wrapper">
                      <Img fluid={teamMember.tileImage.fluid} />
                    </div>
                    <QuoteMarks data-anim data-anim-fade-in className="quote__image--decal" />
                  </div>
                )}
                {body && (
                  <div
                    data-anim
                    data-anim-fade-in
                    className="quote__body p two-col-quote__body"
                    dangerouslySetInnerHTML={{ __html: `<div> ${body} </div>` }}
                  />
                )}
                {teamMember && (
                  <div className="quote__team-member two-col-quote__team-member">
                    <SlideButton text={`${teamMember.firstName} ${teamMember.lastName}`} destination="/our-people" />
                    <p className="caption quote__team-member--position">{teamMember.jobTitle}</p>
                  </div>
                )}
              </div>
            )
          })}
      </div>
    </section>
  )
}

export default TwoColQuote
