import React from 'react'

interface ArrowProps {
  className?: string
}

const Arrow = ({ className }: ArrowProps): JSX.Element => {
  return (
    <svg
      className={className}
      width="29"
      height="63"
      viewBox="0 0 29 63"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.9961 59L12.9961 1.31134e-07L15.9961 0L15.9961 59L12.9961 59Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 49.1211L1.99309 46.8789L14.4965 57.9931L27 46.8789L28.9931 49.1211L14.4965 62.0069L0 49.1211Z"
        fill="white"
      />
    </svg>
  )
}

export default Arrow
