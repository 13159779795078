import React, { useEffect, useRef, useState } from 'react'
import { TeamActiveMember, TeamMember } from '../types/types'
import { lockBody, unlockBody } from '../utils/global'

import Arrow from './atoms/Arrow'
import IconLinkedIn from './atoms/Icon-LinkedIn'
import Img from 'gatsby-image'

interface TeamModalProps {
  isOpen: boolean
  members: TeamMember[]
  closeModal: () => void
  activeMember: number
}

const TeamModal = ({ isOpen, members, closeModal, activeMember }: TeamModalProps): JSX.Element => {
  const [currentMember, setCurrentMember] = useState<TeamActiveMember>({
    ...members[activeMember],
    index: activeMember,
  })
  const descRef = useRef<HTMLDivElement>(null)

  const [animate, setAnimate] = useState(false)

  useEffect(() => {
    return function cleanup() {
      closeModal()
    }
  }, [])

  const onNextClick = (index) => {
    setCurrentMember({ ...members[index + 1], index: index + 1 })
    handleAnimating()
  }

  const onPrevClick = (index) => {
    setCurrentMember({ ...members[index - 1], index: index - 1 })
    handleAnimating()
  }

  const handleAnimating = () => {
    descRef.current != null ? (descRef.current.scrollTop = 0) : false
    setAnimate(false)
    setTimeout(() => {
      setAnimate(true)
    }, 100)
  }

  useEffect(() => {
    if (!!isOpen) {
      setCurrentMember({ ...members[activeMember], index: activeMember })
      lockBody()
      handleAnimating()
    } else {
      unlockBody()
    }
  }, [isOpen, activeMember])

  return (
    <section className={`team-modal${isOpen ? ' open' : ' closed'}`}>
      <div className="team-modal__left">
        <div className="team-modal__close-icon" onClick={closeModal}></div>
        <div className="team-modal__pagination-btns">
          <div
            onClick={() => (currentMember.index > 0 ? onPrevClick(currentMember.index) : null)}
            className={`team-modal__pagination-btns--left-arrow-wrapper${currentMember.index === 0 ? ' hide' : ''}`}
          >
            <Arrow className="team-modal__pagination-btns--left-arrow" />
          </div>
          <div
            onClick={() => (currentMember.index < members.length - 1 ? onNextClick(currentMember.index) : null)}
            className={`team-modal__pagination-btns--right-arrow-wrapper${
              currentMember.index === members.length - 1 ? ' hide' : ''
            }`}
          >
            <Arrow className="team-modal__pagination-btns--right-arrow" />
          </div>
        </div>
        {currentMember && (
          <div className={`team-modal__member${animate ? ' modal-fade-in' : ''}`}>
            {currentMember.firstName && (
              <h2 className="team-modal__member--name">
                {currentMember.firstName} {currentMember.lastName}
              </h2>
            )}
            {currentMember.jobTitle && <p className="team-modal__member--title body-large">{currentMember.jobTitle}</p>}
            {currentMember.description && (
              <div
                className="p team-modal__member--description"
                dangerouslySetInnerHTML={{
                  __html: `<div> ${currentMember.description} </div>`,
                }}
                ref={descRef}
              />
            )}
            {currentMember.linkedinUrl && (
              <a
                href={currentMember.linkedinUrl}
                className="team-modal__member--linked-in"
                target="_blank"
                rel="noreferrer"
                aria-label="LinkedIn"
              >
                <IconLinkedIn />
              </a>
            )}
          </div>
        )}
      </div>
      <div className="team-modal__right">
        {currentMember.bioImage && (
          <Img
            className={`team-modal__member--photo${animate ? ' modal-img-fade-in' : ''}`}
            fluid={currentMember.bioImage.fluid}
          />
        )}
      </div>
    </section>
  )
}

export default TeamModal
