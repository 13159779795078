import React from 'react'
import SubtitleTag from './atoms/SubtitleTag'
import { ColorSchemeSelector, HeadingTag } from '../utils/global'

interface AlignedTextData {
  body: string
  heading: string
  subtitle: string
  darkMode: boolean
  largeHeading: boolean
  headingH1: boolean
  reversed: boolean
  alternateBackgroundColor: boolean
  addExtraPadding: boolean
}

interface AlignedTextType {
  data: AlignedTextData
}

const DatoCmsAlignedText = ({ data }: AlignedTextType): JSX.Element => {
  const { body, heading, subtitle, darkMode, largeHeading, headingH1, reversed, addExtraPadding } = data
  let { alternateBackgroundColor } = data
  darkMode ? (alternateBackgroundColor = false) : null

  return (
    <section
      data-anim-parent
      className={`${ColorSchemeSelector('aligned-text', alternateBackgroundColor, darkMode)}${
        reversed ? ' aligned-text--reversed' : ''
      }${addExtraPadding ? ' extra-padding' : ''}`}
    >
      <div className="aligned-text__inner container">
        {subtitle ? (
          <>
            <SubtitleTag subtitle={subtitle} className="aligned-text__subtitle-wrapper" />
            <div className="aligned-text__subtitle-line-wrapper">
              <div data-anim data-anim-grow className="feature-line"></div>
            </div>
          </>
        ) : (
          <div className="aligned-text__subtitle-line-wrapper aligned-text__no-subtitle">
            <div data-anim data-anim-grow className="feature-line"></div>
          </div>
        )}
        {heading ? HeadingTag(headingH1 ? 1 : largeHeading ? 2 : 3, 'aligned-text__header', heading) : null}
        {body && <div className="p aligned-text__body" dangerouslySetInnerHTML={{ __html: `<div> ${body} </div>` }} />}
      </div>
    </section>
  )
}

export default DatoCmsAlignedText
