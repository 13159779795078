/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from 'react'

import Animations from '../scripts/animations'
import DatoCmsAlignedText from '../components/AlignedText'
import DatoCmsCtaBlock from '../components/CTABlock'
import DatoCmsCenteredText from '../components/CenteredText'
import DatoCmsContactBlock from '../components/ContactBlock'
import DatoCmsHeroBanner from '../components/HeroBanner'
import DatoCmsHeroBannerFluid from '../components/HeroBannerFluid'
import DatoCmsImageAndText from '../components/ImageAndText'
import DatoCmsInsightsListing from '../components/InsightsListing'
import DatoCmsJobListing from '../components/JobListing'
import DatoCmsMultiColIconBlock from '../components/MultiColIcon'
import DatoCmsPartnerBlock from '../components/PartnerBlock'
import DatoCmsQuote from '../components/Quote'
import DatoCmsQuoteCarousel from '../components/QuoteCarousel'
import DatoCmsTeamCarousel from '../components/TeamCarousel'
import DatoCmsTeamListing from '../components/TeamListing'
import DatoCmsTeamWithSort from '../components/TeamWithSort'
import DatoCmsTextBlock from '../components/TextBlock'
import DatoCmsTwoColImageAndText from '../components/TwoColImageAndText'
import DatoCmsTwoColText from '../components/TwoColText'
import DatoCmsTwoColumnQuote from '../components/TwoColQuote'
import DatoCmsVideoBlock from '../components/VideoBlock'
import { GlobalSeo } from '../types/types'
import { Helmet } from 'react-helmet'
import Layout from '../layout/Layout'

interface PageTemplateProps {
  pageContext: {
    page: {
      pageTitle: string
      slug: string
      components: any
      transparentHeader: boolean
      transparentHeaderLight: boolean
      displayInHeader: boolean
      disableLink: boolean
      displayAsButton: boolean
      externalUrl: string
      noIndex: boolean
      seo: {
        title: string
        description: string
        image: {
          url: string
        }
      }
    }
    globalSeo: GlobalSeo
  }
}

interface Blocks {
  DatoCmsQuote: any
  DatoCmsHeroBanner: any
  DatoCmsHeroBannerFluid: any
  DatoCmsCenteredText: any
  DatoCmsAlignedText: any
  DatoCmsCtaBlock: any
  DatoCmsTwoColText: any
  DatoCmsImageAndText: any
  DatoCmsTeamCarousel: any
  DatoCmsTeamListing: any
  DatoCmsMultiColIconBlock: any
  DatoCmsContactBlock: any
  DatoCmsTextBlock: any
  DatoCmsInsightsListing: any
  DatoCmsJobListing: any
  DatoCmsVideoBlock: any
  DatoCmsTeamWithSort: any
  DatoCmsPartnerBlock: any
  DatoCmsTwoColImageAndText: any
  DatoCmsTwoColumnQuote: any
  DatoCmsQuoteCarousel: any
}

const PageTemplate = ({ pageContext }: PageTemplateProps): JSX.Element => {
  const { components, transparentHeader, transparentHeaderLight, seo, noIndex } = pageContext.page
  const { globalSeo } = pageContext
  const { siteName, fallbackSeo } = globalSeo

  const blocks: Blocks = {
    DatoCmsQuote: DatoCmsQuote,
    DatoCmsHeroBanner: DatoCmsHeroBanner,
    DatoCmsHeroBannerFluid: DatoCmsHeroBannerFluid,
    DatoCmsCenteredText: DatoCmsCenteredText,
    DatoCmsAlignedText: DatoCmsAlignedText,
    DatoCmsCtaBlock: DatoCmsCtaBlock,
    DatoCmsTwoColText: DatoCmsTwoColText,
    DatoCmsImageAndText: DatoCmsImageAndText,
    DatoCmsTeamCarousel: DatoCmsTeamCarousel,
    DatoCmsTeamListing: DatoCmsTeamListing,
    DatoCmsMultiColIconBlock: DatoCmsMultiColIconBlock,
    DatoCmsContactBlock: DatoCmsContactBlock,
    DatoCmsTextBlock: DatoCmsTextBlock,
    DatoCmsInsightsListing: DatoCmsInsightsListing,
    DatoCmsJobListing: DatoCmsJobListing,
    DatoCmsVideoBlock: DatoCmsVideoBlock,
    DatoCmsTeamWithSort: DatoCmsTeamWithSort,
    DatoCmsPartnerBlock: DatoCmsPartnerBlock,
    DatoCmsTwoColImageAndText: DatoCmsTwoColImageAndText,
    DatoCmsTwoColumnQuote: DatoCmsTwoColumnQuote,
    DatoCmsQuoteCarousel: DatoCmsQuoteCarousel,
  }

  useEffect(() => {
    Animations.init()
  }, [])

  let seoData = {
    title: siteName,
    description: fallbackSeo.description,
    image: fallbackSeo.image.url,
    url: 'https://sayers.group/',
  }

  if (seo) {
    seoData = {
      title: seo.title ? `${siteName} - ${seo.title}` : siteName,
      description: seo.description ? seo.description : fallbackSeo.description,
      image: seo.image ? seo.image.url : fallbackSeo.image.url,
      url:
        pageContext.page.slug === 'index' ? 'https://sayers.group/' : 'https://sayers.group/' + pageContext.page.slug,
    }
  }

  return (
    <>
      <Helmet htmlAttributes={{ lang: 'en' }}>
        <title>{seoData.title}</title>
        {seoData.description && <meta name="description" content={seoData.description} />}
        {seoData.image && <meta name="image" content={seoData.image} />}
        {seoData.title && <meta property="og:title" content={seoData.title} />}
        {seoData.description && <meta property="og:description" content={seoData.description} />}
        {seoData.image && <meta name="og:image" content={seoData.image} />}
        {seoData.url && <meta property="og:url" content={seoData.url} />}
        <meta name="msvalidate.01" content="DCA06C782ADDDB0B6E1250E3BA1B5CBA" />
        <meta name="cf-2fa-verify" content="dEBO8TzWA1pxGJnSi7T9" />
        {noIndex && <meta name="robots" content="noindex" />}
      </Helmet>
      <Layout transparent={transparentHeader} darkMode={transparentHeaderLight}>
        {components.map((component: any, index: number) => {
          const BlockName = blocks[component?.__typename]
          if (BlockName) {
            return <BlockName data={component} key={`${index}-${component.id}`} />
          }
        })}
      </Layout>
    </>
  )
}

export default PageTemplate
