import React from 'react'
import Img from 'gatsby-image'
import SubtitleTag from './atoms/SubtitleTag'
import { ColorSchemeSelector, HeadingTag, renderButtons } from '../utils/global'
import { ButtonsType, MediaType } from '../types/types'

interface TwoColImageAndTextProps {
  data: {
    columns: {
      heading: string
      largeHeading: boolean
      featureLine: boolean
      subtitle: string
      body: string
      media: MediaType
      buttons: ButtonsType[]
    }[]
    indent: boolean
    darkMode: boolean
    reversed: boolean
    alternateBackgroundColor: boolean
  }
}

const TwoColImageAndText = ({ data }: TwoColImageAndTextProps): JSX.Element => {
  const { columns, darkMode, reversed, indent } = data
  let { alternateBackgroundColor } = data
  darkMode ? (alternateBackgroundColor = false) : null

  return (
    <section
      data-anim-parent
      className={`${ColorSchemeSelector('two-col-image-and-text', alternateBackgroundColor, darkMode)}${
        reversed ? ' two-col-image-and-text--reversed' : ''
      }${indent ? ' indent' : ''}`}
    >
      <div className="two-col-image-and-text__inner container">
        {columns &&
          columns.map(({ heading, largeHeading, featureLine, subtitle, body, media, buttons }, i) => (
            <div className={`two-col-image-and-text__col col-${i + 1}`} key={`${heading}-${i}`}>
              {subtitle && <SubtitleTag subtitle={subtitle} className="two-col-image-and-text__subtitle" />}
              {heading && HeadingTag(largeHeading ? 2 : 3, 'two-col-image-and-text__heading', heading)}
              {featureLine && (
                <div
                  data-anim
                  data-anim-grow
                  className={`feature-line ${largeHeading ? 'feature--large-heading' : ''}`}
                ></div>
              )}
              {media && <Img className="two-col-image-and-text__image" fluid={media.fluid} loading="eager" />}
              {body && (
                <div
                  className="p two-col-image-and-text__body"
                  dangerouslySetInnerHTML={{ __html: `<div> ${body} </div>` }}
                />
              )}
              {buttons && (
                <div className={`button-layout${buttons.length > 1 ? ' detailed' : ''}`}>{renderButtons(buttons)}</div>
              )}
            </div>
          ))}
      </div>
    </section>
  )
}

export default TwoColImageAndText
