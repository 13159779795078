import React from 'react'

interface SubtitleTagProps {
  subtitle: string
  className?: string
}

const SubtitleTag = ({ subtitle, className }: SubtitleTagProps): JSX.Element => {
  return (
    <div className={`subtitle ${className}`}>
      <p data-anim data-anim-translate-up className="h4 subtitle__text">
        {subtitle}
      </p>
    </div>
  )
}

export default SubtitleTag
