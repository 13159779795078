import React from 'react'
import { ColorSchemeSelector } from '../utils/global'

interface TextBlock {
  body: string
  darkMode: boolean
  alternateBackgroundColor: boolean
}

interface TextBlockType {
  data: TextBlock
}

const DatoCmsTextBlock = ({ data }: TextBlockType): JSX.Element => {
  const { body, darkMode } = data
  let { alternateBackgroundColor } = data
  darkMode ? (alternateBackgroundColor = false) : null

  return (
    <section data-anim-parent className={`${ColorSchemeSelector('text-block', alternateBackgroundColor, darkMode)}`}>
      <div className="text-block__inner container">
        {body && <div className="p text-block__body" dangerouslySetInnerHTML={{ __html: `<div> ${body} </div>` }} />}
      </div>
    </section>
  )
}

export default DatoCmsTextBlock
