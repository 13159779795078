import 'swiper/scss/navigation'
import 'swiper/scss/pagination'

import * as React from 'react'

import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Navigation, Pagination } from 'swiper'

import DatoCmsQuote from './Quote'
import { QuoteCarousel } from '../types/types'

SwiperCore.use([Navigation, Pagination])
interface QuoteBlockType {
  data: QuoteCarousel
}

const DatoCmsQuoteCarousel = ({ data }: QuoteBlockType): JSX.Element => {
  const { quotes } = data

  return (
    <section data-anim-parent className="quote-carousel">
      <Swiper
        spaceBetween={0}
        slidesPerView="auto"
        observer={true}
        observeParents={true}
        navigation
        pagination={{ clickable: true }}
      >
        {quotes.length &&
          quotes.map((quote, index) => {
            return (
              <SwiperSlide key={index}>
                <DatoCmsQuote data={quote} />
              </SwiperSlide>
            )
          })}
      </Swiper>
    </section>
  )
}

export default DatoCmsQuoteCarousel
