import React from 'react'
import DropDownArrow from './DropDownArrow'

interface InsightsDropDownProps {
  dropDown: boolean
  toggleDropDown: () => void
  selectedCategory: string
  handleFilter: (e) => void
  allCategories: {
    category: string
  }[]
  totalCategoryPosts: {
    [key: string]: number
  }
}

const InsightsDropDown = ({
  dropDown,
  toggleDropDown,
  selectedCategory,
  handleFilter,
  allCategories,
  totalCategoryPosts,
}: InsightsDropDownProps): JSX.Element => {
  return (
    <div className="insights-listing__select-menu">
      <div
        className={`insights-listing__select-menu--drop-down${dropDown ? ' open' : ' closed'}`}
        onClick={toggleDropDown}
      >
        <h3 className="insights-listing__select-menu--selected">{selectedCategory}</h3>
        <div className="insights-listing__select-menu--toggle">
          <DropDownArrow />
        </div>
      </div>
      <ul className={`insights-listing__select-menu--list${dropDown ? ' open' : ' closed'}`}>
        {selectedCategory !== 'show all' ? (
          <li className="insights-listing__select-menu--list-item" onClick={handleFilter}>
            <h3>show all</h3>
          </li>
        ) : null}
        {allCategories.map((category) => {
          return selectedCategory !== category.category.toLowerCase() ? (
            totalCategoryPosts[category.category] > 0 ? (
              <li className="insights-listing__select-menu--list-item" onClick={handleFilter} key={category.category}>
                <h3>{category.category.toLowerCase()}</h3>
              </li>
            ) : (
              <li
                className="insights-listing__select-menu--list-item insights-listing__disabled"
                key={category.category}
              >
                <h3>{category.category.toLowerCase()}</h3>
              </li>
            )
          ) : null
        })}
      </ul>
    </div>
  )
}

export default InsightsDropDown
