import React from 'react'

interface ExternalLinkIconProps {
  title: string
}

const ExternalLinkIcon = ({ title }: ExternalLinkIconProps): JSX.Element => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <title id="svgTitle">{title}</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 2V16H16V9H18V16C18 17.1 17.1 18 16 18H2C0.89 18 0 17.1 0 16V2C0 0.9 0.89 0 2 0H9V2H2ZM11 2V0H18V7H16V3.41L6.17 13.24L4.76 11.83L14.59 2H11Z"
        fill="white"
      />
    </svg>
  )
}

export default ExternalLinkIcon
