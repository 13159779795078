import React from 'react'

interface InsightsCategoryMenuProps {
  selectedCategory: string
  handleFilter: (e) => void
  allCategories: {
    category: string
  }[]
  totalCategoryPosts: {
    [key: string]: number
  }
}

const InsightsCategoryMenu = ({
  selectedCategory,
  handleFilter,
  allCategories,
  totalCategoryPosts,
}: InsightsCategoryMenuProps): JSX.Element => {
  return (
    <ul className="insights-listing__categories-select">
      <li
        className={`insights-listing__categories-select--item${selectedCategory === 'show all' ? ' selected' : ''}`}
        onClick={handleFilter}
      >
        Show All
      </li>
      {allCategories.map((category) => {
        return totalCategoryPosts[category.category] > 0 ? (
          <li
            className={`insights-listing__categories-select--item${
              selectedCategory.toLowerCase() === category.category.toLowerCase() ? ' selected' : ''
            }`}
            key={category.category}
            onClick={handleFilter}
          >
            {category.category}
          </li>
        ) : (
          <li className="insights-listing__categories-select--item disabled" key={category.category}>
            {category.category}
          </li>
        )
      })}
    </ul>
  )
}

export default InsightsCategoryMenu
