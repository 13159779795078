import React, { useEffect } from 'react'

import { ColorSchemeSelector, HeadingTag, renderButtons } from '../utils/global'
import Img from 'gatsby-image'

import SubtitleTag from './atoms/SubtitleTag'
import { ButtonsType, MediaType } from '../types/types'

import 'plyr/dist/plyr.css'

interface DataType {
  body: string
  heading: string
  subtitle: string
  media: MediaType
  darkMode: boolean
  largeHeading: boolean
  reversed: boolean
  fullScreen: boolean
  wideText: boolean
  alternateBackgroundColor: boolean
  buttons: ButtonsType[]
}

interface ImageAndTextType {
  data: DataType
}

const ImageAndText = ({ data }: ImageAndTextType): JSX.Element => {
  const { body, heading, subtitle, media, darkMode, largeHeading, reversed, fullScreen, wideText, buttons } = data
  let { alternateBackgroundColor } = data
  darkMode ? (alternateBackgroundColor = false) : null

  const options = {
    autoplay: true,
    loop: {
      active: true,
    },
    controls: '',
    volume: 0,
    muted: true,
    clickToPlay: false,
  }

  useEffect(() => {
    if (typeof window !== 'undefined' && typeof document !== 'undefined') {
      // eslint-disable-next-line
      const Plyr = require('plyr')
      Array.from(document.querySelectorAll('#player')).map((p) => new Plyr(p, options))
    }
  }, [!media?.isImage])

  return (
    <section
      data-anim-parent
      className={`${ColorSchemeSelector('image-and-text', alternateBackgroundColor, darkMode)}${
        reversed ? ' image-and-text--reversed' : ''
      }${fullScreen ? ' image-and-text--full-screen' : ''}`}
    >
      <div className="image-and-text__inner container">
        <div className={`image-and-text__left-col${wideText ? ' wide-text' : ''}`}>
          {subtitle && <SubtitleTag subtitle={subtitle} className="image-and-text__subtitle-wrapper" />}
          {heading && HeadingTag(largeHeading ? 2 : 3, 'image-and-text__header', heading)}
          {fullScreen && <div data-anim data-anim-grow className="feature-line full-screen"></div>}
          {body && (
            <div className="p image-and-text__body" dangerouslySetInnerHTML={{ __html: `<div> ${body} </div>` }} />
          )}
          {buttons && (
            <div className={`button-layout${buttons.length > 1 ? ' detailed' : ''}`}>{renderButtons(buttons)}</div>
          )}
        </div>

        <div
          className={`image-and-text__right-col ${
            !fullScreen ? 'image-and-text__right-col--padd' : 'image-and-text__right-col--full-screen'
          }`}
        >
          {!fullScreen && (
            <div
              data-anim
              data-anim-grow
              className={`feature-line ${largeHeading ? 'feature--large-heading' : ''}`}
            ></div>
          )}
          {media ? (
            media.isImage ? (
              <Img
                className={`image-and-text__image ${fullScreen ? 'full-img' : ''}`}
                fluid={media.fluid}
                loading="eager"
              />
            ) : (
              <div className="video-container">
                <video id="player" playsInline>
                  <source src={media?.url} type="video/mp4" />
                </video>
              </div>
            )
          ) : (
            <div className="image-and-text__no-media-panel"></div>
          )}
        </div>
      </div>
    </section>
  )
}

export default ImageAndText
