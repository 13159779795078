import React, { useEffect, useRef } from 'react'
import Img, { FluidObject } from 'gatsby-image'
import { HeadingTag } from '../utils/global'
import FluidBackground from '../scripts/fluidBackground'

import Arrow from './atoms/Arrow'
import QuotationFluid from './atoms/QuotationFluid'

interface HeroBannerFluidProps {
  data: {
    body: string
    title: string
    backgroundImage: {
      fluid: FluidObject
      url: string
    }
    mobileBackground: {
      fluid: FluidObject
      url: string
    }
    reverseTextColor: boolean
  }
}

const HeroBannerFluid = ({ data }: HeroBannerFluidProps): JSX.Element => {
  const { body, title, backgroundImage, mobileBackground, reverseTextColor } = data
  const heroRef = useRef<null | HTMLDivElement>(null)
  const fluidRef = useRef<null | HTMLDivElement>(null)

  const scrollTo = () => {
    heroRef.current?.nextElementSibling?.scrollIntoView({ behavior: 'smooth' })
  }

  useEffect(() => {
    if (fluidRef.current) {
      new FluidBackground(fluidRef.current, backgroundImage.url)
    }
  }, [])

  return (
    <section
      ref={heroRef}
      className={`hero-banner${reverseTextColor ? ' reverse-color' : ''} hero-banner__full-height`}
    >
      {backgroundImage && (
        <div className="hero-banner__background hero-banner__fluid-desktop" id="hero-background" ref={fluidRef}></div>
      )}
      {mobileBackground && (
        <div className="hero-banner__fluid-mobile">
          <Img fluid={mobileBackground.fluid} />
        </div>
      )}
      <div className="hero-banner__inner container">
        {title && HeadingTag(1, 'hero-banner__title', title)}
        <div className="hero-banner__quote-mark">
          <QuotationFluid className="hero-banner__quote" />
        </div>
        {body && (
          <div className="hero-banner__body body-large" dangerouslySetInnerHTML={{ __html: `<div> ${body} </div>` }} />
        )}
        <div onClick={scrollTo} className="hero-banner__arrow-wrapper">
          <Arrow className="hero-banner__arrow" />
        </div>
      </div>
    </section>
  )
}

export default HeroBannerFluid
