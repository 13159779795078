import { ColorSchemeSelector, HeadingTag } from '../utils/global'
import React, { createRef, useEffect, useRef, useState } from 'react'

import DropDownArrow from './atoms/DropDownArrow'
import MagneticButton from '../scripts/magnetic-button'
import Quotation from './atoms/Quotation'
import SubtitleTag from './atoms/SubtitleTag'

interface ContactBlockData {
  body: string
  heading: string
  formSuccessMessage: string
  formFailedMessage: string
  subtitle: string
  darkMode: boolean
  alternateBackgroundColor: boolean
}

interface ContactBlockType {
  data: ContactBlockData
}

const ContactBlock = ({ data }: ContactBlockType): JSX.Element => {
  const { body, heading, subtitle, darkMode, formFailedMessage, formSuccessMessage } = data
  const dropDownRef = useRef<HTMLDivElement>(null)
  let { alternateBackgroundColor } = data
  darkMode ? (alternateBackgroundColor = false) : null

  const formInitial = {
    name: '',
    email: '',
    phone: '',
    subject: 'General Enquiry',
    message: '',
  }

  const [btnRefs, setBtnRefs] = useState([])
  const [values, setValues] = useState(formInitial)
  const [formSubmitted, setFormSubmitted] = useState(false)
  const [formSuccess, setFormSuccess] = useState(false)
  const [dropDown, setDropDown] = useState(false)
  const [formSubjects] = useState(['General Enquiry', 'Wealth', 'Advisory & Investments'])
  const [formName, setFormName] = useState(formSubjects[0])
  const isValid = values.name !== '' && values.email !== '' && values.phone !== '' && values.message !== ''

  const handleInputChange = (e) => {
    const { name, value } = e.target
    setValues({ ...values, [name]: value })
  }

  const encode = (data) => {
    return Object.keys(data)
      .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
      .join('&')
  }

  const onFormSubmit = async (e) => {
    e.preventDefault()

    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({ 'form-name': formName, ...values }),
    })
      .then(() => {
        setFormSubmitted(true)
        setFormSuccess(true)
      })
      .catch((error) => {
        setFormSubmitted(true)
        setFormSuccess(false)
        alert(error)
      })
  }

  useEffect(() => {
    setBtnRefs((btnRefs) =>
      Array(formSubjects.length)
        .fill()
        .map((_, i) => btnRefs[i] || createRef())
    )
  }, [formSubjects.length])

  useEffect(() => {
    if (btnRefs.length && btnRefs[0].current) {
      btnRefs.forEach((ref) => {
        new MagneticButton(ref.current)
      })
    }
  }, [btnRefs])

  const resetForm = () => {
    setFormSuccess(false)
    setFormSubmitted(false)
    setValues(formInitial)
  }

  const toggleDropDown = () => {
    setDropDown(!dropDown)
  }

  const setFormSubject = (e) => {
    setFormName(e.target.innerText)
    setValues({ ...values, subject: e.target.innerText })
    toggleDropDown()
  }

  const handleDropDownClose = (e) => {
    if (e.target !== dropDownRef.current && dropDown) {
      toggleDropDown()
    }
  }

  const formInner = (subject, i) => (
    <>
      <div className="contact-block__form--input">
        <label htmlFor={`name-${i}`}>Name *</label>
        <input
          id={`name-${i}`}
          name="name"
          type="text"
          value={values.name}
          onChange={handleInputChange}
          aria-label={`${subject} Form Name Input`}
        />
      </div>
      <div className="contact-block__form--input">
        <label htmlFor={`email-${i}`}>Email *</label>
        <input
          id={`email-${i}`}
          name="email"
          type="email"
          value={values.email}
          onChange={handleInputChange}
          aria-label={`${subject} Form Email Input`}
        />
      </div>
      <div className="contact-block__form--input">
        <label htmlFor={`phone-${i}`}>Phone *</label>
        <input
          id={`phone-${i}`}
          name="phone"
          type="tel"
          value={values.phone}
          onChange={handleInputChange}
          aria-label={`${subject} Form Phone Input`}
        />
      </div>
      <div className="contact-block__form--input">
        <label htmlFor="subject">Subject *</label>
        <div
          className={`contact-block__drop-down ${dropDown ? 'open' : 'closed'}`}
          onClick={toggleDropDown}
          ref={dropDownRef}
          aria-label={`${subject} Form Subject Input`}
        >
          <p>{formName}</p>
          <div className="contact-block__drop-down--toggle">
            <DropDownArrow />
          </div>
        </div>
        <ul className={`contact-block__drop-down--list ${dropDown ? 'open' : 'closed'}`}>
          {formSubjects.map((subject) => {
            if (subject !== formName) {
              return (
                <li
                  className="contact-block__drop-down--list-item"
                  key={subject}
                  value={subject}
                  onClick={setFormSubject}
                >
                  <p>{subject}</p>
                </li>
              )
            }
          })}
        </ul>
      </div>
      <div className="contact-block__form--input">
        <label htmlFor={`message-${i}`}>Message</label>
        <textarea
          id={`message-${i}`}
          name="message"
          value={values.message}
          onChange={handleInputChange}
          aria-label={`${subject} Form Message Input`}
        />
      </div>
      <button
        ref={btnRefs[i]}
        className="contact-block__form--button button-standard"
        type="submit"
        disabled={isValid === false}
      >
        Send
      </button>
    </>
  )

  return (
    <section
      data-anim-parent
      className={ColorSchemeSelector('contact-block', alternateBackgroundColor, darkMode)}
      onClick={handleDropDownClose}
    >
      <div className="contact-block__inner container">
        <Quotation className="contact-block__quotation" />
        <div className="contact-block__left">
          {subtitle && <SubtitleTag subtitle={subtitle} className="contact-block__subtitle-wrapper" />}
          {heading && HeadingTag(2, 'contact-block__heading', heading)}
          {body && (
            <div className="p contact-block__body" dangerouslySetInnerHTML={{ __html: `<div> ${body} </div>` }} />
          )}
        </div>
        {formSubmitted && formSuccess ? (
          <div className="h3 contact-block__message">
            <div className="h3" dangerouslySetInnerHTML={{ __html: `<div> ${formSuccessMessage} </div>` }} />
            <button className="button-standard" onClick={resetForm}>
              Close
            </button>
          </div>
        ) : formSubmitted ? (
          <div className="h3 contact-block__message">
            <div className="h3" dangerouslySetInnerHTML={{ __html: `<div> ${formFailedMessage} </div>` }} />
            <button className="button-standard" onClick={resetForm}>
              Try again
            </button>
          </div>
        ) : (
          formSubjects.map((subject, i) => (
            <form
              onSubmit={onFormSubmit}
              className={`contact-block__form ${formName !== subject ? 'hidden' : ''}`}
              data-netlify="true"
              method="POST"
              name={subject}
              key={i}
            >
              <input type="hidden" name="form-name" value={subject} />
              {formInner(subject, i)}
            </form>
          ))
        )}
      </div>
    </section>
  )
}

export default ContactBlock
