import { Link, graphql, useStaticQuery } from 'gatsby'
import React, { useEffect, useRef, useState } from 'react'

import ArticleTile from '../components/ArticleTile'
import { ColorSchemeSelector } from '../utils/global'
import InsightsCategoryMenu from './atoms/InsightsCategoryMenu'
import InsightsDropDown from './atoms/InsightsDropDown'

interface InsightsListingProps {
  data: {
    alternateBackground: boolean
    darkMode: boolean
    id: string
    __typename: string
  }
}

const InsightsListing = ({ data }: InsightsListingProps): JSX.Element => {
  const { allDatoCmsArticle, allDatoCmsArticleCategory } = useStaticQuery(graphql`
    {
      allDatoCmsArticle(sort: { order: DESC, fields: date }) {
        nodes {
          title
          slug
          author
          date
          category {
            category
            categoryIcon {
              url
            }
          }
          meta {
            createdAt
          }
          articleType {
            ... on DatoCmsArticleBlog {
              id
              bannerImage {
                fluid {
                  aspectRatio
                  sizes
                  src
                  srcSet
                }
              }
              tileImage {
                fluid {
                  aspectRatio
                  sizes
                  src
                  srcSet
                }
              }
              __typename
            }
            ... on DatoCmsArticlePodcast {
              id
              bannerImage {
                fluid(maxWidth: 3600, forceBlurhash: true) {
                  aspectRatio
                  sizes
                  src
                  srcSet
                }
              }
              tileImage {
                fluid(maxWidth: 600, forceBlurhash: true) {
                  aspectRatio
                  sizes
                  src
                  srcSet
                }
              }
              __typename
            }
            ... on DatoCmsArticleVideo {
              id
              __typename
            }
            ... on DatoCmsArticleExternalLink {
              id
              url
              haveDetailedPage
              linkToMedia {
                url
              }
              __typename
            }
          }
          __typename
        }
      }
      allDatoCmsArticleCategory(sort: { fields: category }) {
        nodes {
          category
        }
      }
    }
  `)

  const [allCategories] = useState(allDatoCmsArticleCategory.nodes)
  const [totalArticles, setTotalArticles] = useState(allDatoCmsArticle.nodes)
  const [selectedCategory, setSelectedCategory] = useState('show all')
  const [dropDown, setDropDown] = useState(false)
  const [totalCategoryPosts, setTotalCategoryPosts] = useState({})
  const [masonryLoaded, setLoaded] = useState(false)
  const masonryGrid = useRef(null)

  const { darkMode } = data
  let { alternateBackground } = data
  darkMode ? (alternateBackground = false) : null
  // eslint-disable-next-line
  const [colc, setColc] = useState<any>(null)

  const handleFilter = (e) => {
    if (typeof window !== 'undefined') {
      colc?.destroy()
    }
    setColc(null)
    setLoaded(false)

    if (e.target.innerText.toLowerCase() === 'show all') {
      setTotalArticles(allDatoCmsArticle.nodes)
    } else {
      setTotalArticles(
        allDatoCmsArticle.nodes.filter(
          (article) => article.category.category.toLowerCase() === e.target.innerText.toLowerCase()
        )
      )
    }

    setSelectedCategory(e.target.innerText.toLowerCase())

    if (dropDown) {
      toggleDropDown()
    }
  }

  const toggleDropDown = () => {
    setDropDown(!dropDown)
  }

  useEffect(() => {
    const categoriesMap = allCategories.map((category) => category.category)
    setTotalCategoryPosts(
      categoriesMap.reduce(
        (o, key) =>
          Object.assign(o, { [key]: totalArticles.filter((article) => article.category.category === key).length }),
        {}
      )
    )
  }, [allCategories])

  useEffect(() => {
    const grid = masonryGrid?.current

    setTimeout(() => {
      if (typeof window !== 'undefined') {
        const Colcade = require('colcade')
        if (grid) {
          setColc(
            new Colcade(grid, {
              columns: '.masonry-grid__col',
              items: '.masonry-item',
            })
          )
        }
        setLoaded(true)
      }
    }, 100)

    return () => {
      if (typeof window !== 'undefined') {
        colc?.destroy()
      }
    }
  }, [totalArticles, masonryGrid])

  return (
    <section className={`${ColorSchemeSelector('insights-listing masonry-grid', alternateBackground, darkMode)}`}>
      <div className="insights-listing__inner container">
        <InsightsCategoryMenu
          selectedCategory={selectedCategory}
          handleFilter={handleFilter}
          allCategories={allCategories}
          totalCategoryPosts={totalCategoryPosts}
        />
        <InsightsDropDown
          dropDown={dropDown}
          toggleDropDown={toggleDropDown}
          selectedCategory={selectedCategory}
          handleFilter={handleFilter}
          allCategories={allCategories}
          totalCategoryPosts={totalCategoryPosts}
        />
        <div className="masonry-grid__inner" ref={masonryGrid}>
          <div className="masonry-grid__col masonry-grid__col--1"></div>
          <div className="masonry-grid__col masonry-grid__col--2"></div>
          <div className="masonry-grid__col masonry-grid__col--3"></div>
          {totalArticles.map((item, i) => {
            if (i < totalArticles.length) {
              if (
                item.articleType[0].__typename === 'DatoCmsArticleExternalLink' &&
                !item.articleType[0].haveDetailedPage
              ) {
                return (
                  <a
                    href={
                      item.articleType[0].linkToMedia?.url
                        ? item.articleType[0].linkToMedia.url
                        : item.articleType[0].url
                    }
                    target="_blank"
                    rel="noreferrer"
                    aria-label={item.title}
                    key={i}
                    className={`masonry-item ${masonryLoaded ? 'loaded' : 'loading'}`}
                  >
                    <ArticleTile item={item} />
                  </a>
                )
              } else {
                return (
                  <Link
                    to={`/insights/${item.slug}`}
                    className={`insights-listing__item masonry-item ${masonryLoaded ? 'loaded' : 'loading'}`}
                    key={i}
                    aria-label={item.title}
                  >
                    <ArticleTile item={item} />
                  </Link>
                )
              }
            }
          })}
        </div>
      </div>
    </section>
  )
}

export default InsightsListing
